<template>
  <div id="screen">
    <div class="content">
      <div id="hadder">{{ $t('welcome') }}</div>
      <div>
        <img
          src="@/assets/peng1.png"
          id="first_png"
          v-once
        />
      </div>
      <div id="first_text">{{ $t('welcome_1') }}</div>
      <div
        id="first_unlt"
        v-html="$t('welcome_2')"
      ></div>
      <div
        id="l1ptext"
        v-html="$t('welcome_3')"
      ></div>
      <div id="hadder2">{{ $t('ref_system') }}</div>
      <div>
        <img
          src="@/assets/peng2.png"
          id="second_png"
          v-once
        />
      </div>
      <div
        id="last_text"
        v-html="$t('welcome_5')"
      ></div>
      <div class="button-container">
        <div id="next-bloom"></div>
        <animated-button @click="goHome">{{ $t('continue') }}</animated-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import AnimatedButton from './views/AnimatedButton.vue';
import socket from '../socket';

export default defineComponent({
  name: 'WelcomePage',
  components: {
    AnimatedButton,
  },
  methods: {
    goHome() {
      socket.emit('acceptTos', window.Telegram.WebApp.initData);
      this.$router.push('/inactive');
    },
  },
});
</script>

<style scoped>
#screen {
  background-color: #0a0a0a;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  touch-action: none !important;
}
.content {
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#hadder {
  color: #ffffff;
  font-family: Mont;
  font-size: 26px;
  font-weight: 800;
  line-height: 33.23px;
  text-align: left;
  margin-left: 15px;
  margin-top: 20px;
}
#first_png {
  width: 90%;
  height: auto;
  top: -435px;
  left: 633px;
  gap: 0px;
  border-radius: 15px;
  margin-left: 15px;
  margin-top: 18px;
}
#first_unlt {
  color: #ffffff;
  font-family: Mont;
  font-size: 15px;
  font-weight: 700;
  line-height: 19.17px;
  text-align: left;
  margin-left: 15px;
  text-align: left;
  margin-right: 15px;
  margin-bottom: 20px;
}
#first_text {
  color: #f6f6f6;
  text-indent: 0px;
  margin-top: 9px;
  weight: 700;
  size: 15px;
  line-height: 19.17px;
  font-family: 'Mont';
  margin-left: 15px;
  text-align: left;
  margin-right: 15px;
  margin-bottom: 20px;
}
#span1 {
  font-family: 'Mont';
  weight: 800;
  size: 15px;
  line-height: 19.17px;
  margin-left: 15px;
  text-align: left;
}
#l1ptext {
  color: #ffffff;
  font-family: Mont;
  font-size: 15px;
  font-weight: 700;
  line-height: 19.17px;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 45px;
}
#span2 {
  font-family: Mont;
  font-size: 15px;
  font-weight: 700;
  line-height: 19.17px;
  text-align: left;
}
#second_png {
  width: 90%;
  height: auto;
  top: 921px;
  left: 15px;
  gap: 0px;
  border-radius: 15px;
  margin-left: 15px;
  margin-top: 23px;
}
#last_text {
  font-family: Mont;
  font-size: 15px;
  font-weight: 700;
  line-height: 19.17px;
  text-align: left;
  color: #f6f6f6;
  margin-left: 15px;
  margin-top: 27px;
}
#hadder2 {
  font-family: Mont;
  font-size: 26px;
  font-weight: 800;
  line-height: 33.23px;
  text-align: left;
  color: #f6f6f6;
  margin-left: 15px;
  margin-bottom: 9px;
}
.button-container {
  position: relative;
  top: 20px;
  left: 0;
  height: 150px;
  margin-top: -20px;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#next-bloom {
  position: absolute;
  background: radial-gradient(circle, rgb(255 199 0 / 20%) 0%, transparent 60%);
  width: 100%;
  height: 250%;
  top: -30%;
  z-index: 1;
}
button {
  position: relative;
  width: 90%;
  max-width: 400px;
  height: 64px;
  border: none;
  border-radius: 21px;
  font-size: 18px;
  font-weight: 800;
  line-height: 24.24px;
  text-align: center;
  background-color: #ffc700;
  color: black;
  transition: transform 0.2s;
  cursor: pointer;
  z-index: 2;
}
button.clicked {
  animation: click-animation 0.2s;
}
@keyframes click-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
</style>
