<script>
import socket from '@/socket';

export default {
  name: 'AddSlots',
  props: {
    userBalance: Number,
  },
  data() {
    return {
      slotCount: 3,
      slotPrice: 0.5,
    };
  },
  computed: {
    total() {
      return this.slotCount * this.slotPrice;
    },
  },
  methods: {
    incrementSlots() {
      this.slotCount++;
    },
    decrementSlots() {
      if (this.slotCount > 1) {
        this.slotCount--;
      }
    },
    setSlots(amount) {
      this.slotCount = amount;
    },
    buySlots() {
      let windowName;
      if (this.userBalance >= this.slotCount * this.slotPrice) {
        socket.emit(
          'addSlots',
          window.Telegram.WebApp.initData,
          this.slotCount,
          (success) => {
            if (success) {
              windowName = 'isSuccessWindowVisible';
              this.$store.dispatch('setWindowVisibility', {
                windowName,
                value: 'success',
              });
            } else {
              windowName = 'isErrorsWindowVisible';
              this.$store.dispatch('setWindowVisibility', {
                windowName,
                value: 'error',
              });
            }
          }
        );
      }
    },
  },
};
</script>

<template>
  <div class="main">
    <div class="modal">
      <div class="header">
        <p class="status">{{ $t('add_slots') }}</p>
        <button class="butcross">
          <img
            class="cross"
            @click="$emit('close')"
            src="@/assets/plus.svg"
          />
        </button>
      </div>
      <p class="desc">{{ $t('enter_amount_slots') }}</p>

      <div class="amount-slots">
        <!-- <button
          class="minus"
          @click="decrementSlots"
        >
          -
        </button> -->

        <img
          src="@/assets/subtract.png"
          @click="decrementSlots"
          class="minus"
        />

        <p class="amount">{{ slotCount }}</p>

        <!-- <button
          class="plus"
          @click="incrementSlots"
        >
          +
        </button> -->
        <img
          src="@/assets/add.png"
          @click="incrementSlots"
          class="plus"
        />
      </div>

      <div class="variants_value">
        <button
          class="variant_value"
          @click="setSlots(5)"
        >
          5
        </button>
        <button
          class="variant_value"
          @click="setSlots(10)"
        >
          10
        </button>
        <button
          class="variant_value"
          @click="setSlots(20)"
        >
          20
        </button>
        <button
          class="variant_value"
          @click="setSlots(50)"
        >
          50
        </button>
      </div>

      <p class="desc-sum">
        1 {{ $t('slot') }} = {{ slotPrice }}$<br />{{
          $t('sum_paid_from_balance')
        }}
      </p>
      <div class="total">
        <p class="label-total">{{ $t('total') }}:</p>
        <p class="number-total">{{ total.toFixed(1) }}$</p>
      </div>
      <div class="center-btn">
        <button
          class="btn-submit"
          :class="{ disabled: total > userBalance }"
          @click="buySlots"
        >
          {{ total > userBalance ? $t('insufficient_funds') : $t('confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: #78787880;
  backdrop-filter: blur(15px);
}

.modal {
  width: 80%;
  border-radius: 10px;
  background-color: #1a1a1a;
  padding: 25px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.status {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-SemiBold';
  margin-right: 10px;
}

.butcross {
  width: 27px;
  height: 27px;
  display: flex;
  border-radius: 50%;
  border: 0.5px solid rgba(49, 49, 49, 1);
  background: rgba(40, 40, 40, 1);
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: -10px -10px 0 0;
}

.cross {
  width: 20px;
  height: 20px;
  padding: 5px;
  color: rgba(255, 255, 255, 1);
}

.desc {
  margin-top: 5px;
  font-size: 80%;
  font-family: 'Mont-Light';
  color: rgb(255 255 255 / 80%);
  margin-right: 30px;
}

.amount-slots {
  display: flex;
  padding-top: 15px;
  padding-bottom: 10px;
  border-bottom: 1px rgba(35, 35, 35, 1) solid;
  align-items: center;
  justify-content: center;
}

.minus {
  /* border: none; */
  height: 20px;
  width: 20px;
  /* padding: 0;
  font-size: 20px;
  font-family: 'Mont';
  line-height: 1.2;
  border-radius: 50%; */
  cursor: pointer;
  /* color: rgba(26, 26, 26, 1); */
}
.amount {
  width: 100px;
  text-align: center;
  font-family: 'Mont';
  font-size: 30px;
  color: rgba(255, 255, 255, 1);
}
.plus {
  /* border: none; */
  height: 20px;
  width: 20px;
  /* padding: 0;
  font-size: 20px;
  font-family: 'Mont';
  line-height: 1.2;
  border-radius: 50%; */
  cursor: pointer;
  /* color: rgba(26, 26, 26, 1); */
}

.variants_value {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.variant_value {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont';
  background: transparent;
  border: 1px solid rgba(69, 69, 69, 1);
  height: 30px;
  margin-left: 2%;
  border-radius: 30px;
  width: 54px;
  cursor: pointer;
}

.total {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.label-total {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-SemiBold';
  opacity: 0.8;
}
.number-total {
  color: rgba(255, 255, 255, 1);
  font-size: 100%;
  font-family: 'Mont';
  text-align: right;
}

.desc-sum {
  margin-top: 20px;
  font-size: 14px;
  font-family: 'Mont-Light';
  color: rgb(255 255 255 / 80%);
}

.center-btn {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.btn-submit {
  width: -webkit-fill-available;
  border-radius: 15px;
  background-color: rgba(255, 199, 0, 1);
  font-family: 'Mont';
  font-size: 100%;
  height: 54px;
  color: black;
  border: none;
  cursor: pointer;
}

.disabled {
  background-color: rgba(136, 136, 136, 1);
}
</style>
