<script>
export default {
  name: 'AddSlots',
  props: {
    userBalance: Number,
    title: String,
    count: Number,
    price: Number,
    openSucces: null,
    close: null,
  },
  methods: {
    buyReferals(userBalance, price) {
      let windowName;

      if (userBalance >= price) {
        this.openSucces();
      } else {
        windowName = 'isErrorsWindowVisible';
        this.$store.dispatch('setWindowVisibility', {
          windowName,
          value: 'errors',
        });
      }
    },
  },
};
</script>

<template>
  <div class="main">
    <div class="modal">
      <div class="header">
        <p class="status">{{ $t('buying_referrals') }}</p>
        <button class="btn-cross">
          <img
            class="cross"
            @click="close"
            src="@/assets/plus.svg"
          />
        </button>
      </div>

      <p class="desc">{{ $t('confirm_the_purchase') }}</p>
      <p class="count-ref">
        {{ count }} {{ count === 1 ? $t('of_referral') : $t('of_referrals') }}
      </p>

      <p class="desc">{{ $t('for_the_game') }}</p>
      <p class="title-game">{{ title }}</p>

      <p class="desc">{{ $t('your_balance_will_be_debited') }}</p>
      <p class="amount">{{ price }}$</p>

      <div class="btns">
        <button
          class="btn-confirm"
          :class="{ disabled: userBalance < price }"
          @click="buyReferals(userBalance, price)"
        >
          {{ price > userBalance ? $t('insufficient_funds') : $t('confirm') }}
        </button>
        <button
          class="btn-close"
          @click="close"
        >
          {{ $t('close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: #78787880;
  backdrop-filter: blur(15px);
}

.modal {
  width: 80%;
  border-radius: 10px;
  background-color: #1a1a1a;
  padding: 25px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.status {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-SemiBold';
  margin-right: 10px;
}

.btn-cross {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
  border: 0.5px solid rgba(49, 49, 49, 1);
  background: rgba(40, 40, 40, 1);
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: -10px -10px 0 0;
}

.cross {
  width: 27px;
  padding: 5px;
  color: rgba(255, 255, 255, 1);
}

.desc {
  margin-top: 10px;
  font-size: 14px;
  font-family: 'Mont-SemiBold';
  color: rgb(255 255 255 / 50%);
}

.count-ref {
  font-size: 14px;
  font-family: 'Mont-SemiBold';
  color: white;
}

.title-game {
  font-size: 14px;
  font-family: 'Mont-SemiBold';
  color: white;
}

.amount {
  font-size: 22px;
  font-family: 'Mont-SemiBold';
  color: white;
}

.btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}

.btn-confirm {
  border-radius: 15px;
  background-color: rgba(255, 199, 0, 1);
  font-family: 'Mont';
  font-size: 100%;
  height: 54px;
  color: black;
  border: none;
  cursor: pointer;
}

.disabled {
  background-color: rgba(136, 136, 136, 1);
}

.btn-close {
  margin-top: 10px;
  border-radius: 15px;
  background-color: transparent;
  font-family: 'Mont';
  font-size: 100%;
  height: 54px;
  color: white;
  border: 0.5px solid rgba(49, 49, 49, 1);
  cursor: pointer;
}
</style>
