<script>
import socket from '@/socket';

export default {
  name: 'AddSlots',
  props: {
    paymentMethod: Number,
    user: Object,
    hide: null,
  },
  data() {
    return {
      amount: '',
      maxLength: 10,
    };
  },
  computed: {
    total() {
      return this.slotCount * this.slotPrice;
    },
  },
  methods: {
    addDigit(digit) {
      if (this.amount.length < this.maxLength) {
        this.amount += digit;
      }
    },
    removeDigit() {
      if (this.amount.length > 0) {
        this.amount = this.amount.slice(0, -1);
      }
    },
    submitAmount(paymentMethod) {
      const amount = +this.amount;
      switch (paymentMethod) {
        case 1:
          this.runCryptomus(amount);
          break;
        case 2:
          this.runPayeer(amount);
          break;
        case 3:
          this.runStars(amount);
          break;
      }
      this.amount = '';
      this.hide();
    },
    runPayeer(amount) {
      socket.emit(
        'getDataPayeer',
        window.Telegram.WebApp.initData,
        amount,
        (url) => {
          window.Telegram.WebApp.openLink(url);
        }
      );
    },
    runCryptomus(amount) {
      socket.emit(
        'getDataCryptomus',
        window.Telegram.WebApp.initData,
        amount,
        (url) => {
          window.Telegram.WebApp.openLink(url);
        }
      );
    },
    runStars(amount) {
      socket.emit(
        'getDataStars',
        window.Telegram.WebApp.initData,
        amount,
        (url) => {
          if (url) {
            window.Telegram.WebApp.openInvoice(url);
          } else {
            alert("Error");
          }
        }
      );
    },
  },
};
</script>

<template>
  <div class="main">
    <div class="modal">
      <div class="header">
        <p class="status">{{ $t('enter_sum') }}</p>
        <button class="butcross">
          <img
            class="cross"
            @click="hide"
            src="@/assets/plus.svg"
          />
        </button>
      </div>

      <p class="amount">{{ amount ? `${amount}$` : '0$' }}</p>
      <div class="calculator">
        <button
          v-for="n in 9"
          :key="n"
          class="k"
          @click="addDigit(n)"
        >
          {{ n }}
        </button>
        <button
          class="k"
          @click="addDigit('0')"
        >
          0
        </button>
        <button
          class="k"
          @click="removeDigit"
        >
          <img
            class="k remove"
            src="@/assets/clear.svg"
          />
        </button>
      </div>

      <div class="center-btn">
        <button
          class="btn-submit"
          :class="{ disabled: amount.length === 0 }"
          @click="submitAmount(paymentMethod)"
        >
          {{ $t('confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: #78787880;
  backdrop-filter: blur(15px);
}

.modal {
  width: 80%;
  border-radius: 10px;
  background-color: #1a1a1a;
  padding: 25px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.status {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-SemiBold';
  margin-right: 10px;
}

.butcross {
  width: 27px;
  height: 27px;
  display: flex;
  border-radius: 50%;
  border: 0.5px solid rgba(49, 49, 49, 1);
  background: rgba(40, 40, 40, 1);
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: -10px -10px 0 0;
}

.cross {
  width: 20px;
  height: 20px;
  padding: 5px;
  color: rgba(255, 255, 255, 1);
}

.amount {
  font-size: 30px;
  color: rgba(246, 246, 246, 1);
  font-family: 'Mont', sans-serif;
  padding-bottom: 5px;
  margin-top: 20px;
  border-bottom: 0.5px solid rgba(49, 49, 49, 1);
  text-align: center;
}

.calculator {
  padding: 0 10px 0 10px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    'a b c'
    'd e f'
    'g h i'
    '. j k';
  gap: 10px;
  margin-bottom: 20px;
}

.calculator button:nth-child(10) {
  grid-area: j;
}

.calculator button:nth-child(11) {
  grid-area: k;
}

.k {
  color: rgba(246, 246, 246, 1);
  font-family: 'Mont-SemiBold', sans-serif;
  font-size: 30px;
  background-color: rgba(34, 34, 34, 1);
  border: none;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
}

.remove {
  margin: 0 auto;
}

.center-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-submit {
  width: -webkit-fill-available;
  border-radius: 15px;
  background-color: rgba(255, 199, 0, 1);
  font-family: 'Mont';
  font-size: 100%;
  height: 54px;
  color: black;
  border: none;
  cursor: pointer;
}

.disabled {
  background-color: rgba(136, 136, 136, 1);
}
</style>
