<script>
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';

import AccountHeader from './views/AccountHeader.vue';
import DeleteAccount from './modals/DeleteAccount.vue';
import socket from '@/socket';

export default defineComponent({
  name: 'AccountSettings',
  components: {
    AccountHeader,
    DeleteAccount,
  },
  props: {
    number: {
      type: String,
      required: true,
    },
  },
  data() {
    const store = useStore();
    const langUser = computed(() => store.state.user.lang);

    return {
      langUser,
      newNumber: null,
      displayNumber: null,
      isDelete: false,
      classNumber: 'number',
    };
  },
  mounted() {
    window.Telegram.WebApp.BackButton.onClick(() => {
      this.$router.push('/account');
    }).show();

    try {
      this.newNumber = JSON.parse(this.number);
      if (this.newNumber.length == 1) {
        this.newNumber = this.newNumber[0];
        this.displayNumber = this.newNumber;
        this.classNumber = 'number';
      } else {
        this.displayNumber =
          this.langUser === 'ru'
            ? this.newNumber[0] +
              this.$t('and_more') +
              (this.newNumber.length - 1)
            : this.newNumber[0] +
              ' and ' +
              (this.newNumber.length - 1) +
              ' more';
        this.classNumber = 'numbers';
      }
    } catch (err) {
      this.newNumber = this.number;
      this.displayNumber = this.newNumber;
    }

    socket.emit(
      'getAccountClickers',
      window.Telegram.WebApp.initData,
      this.newNumber,
      (accountData) => {
        if (accountData) {
          this.accounts = accountData.map((account) => {
            account.activeCount = account.functions.filter(
              (f) => f.isActive
            ).length;
            account.totalCount = account.functions.length;
            account.isActive = account.functions[0].isActive;
            return account;
          });
        } else {
          alert('Произошла ошибка');
        }
      }
    );
  },
  unmounted() {
    window.Telegram.WebApp.BackButton.hide();
  },
  methods: {
    closeSettings() {
      this.$router.push('/account');
    },
    getAvatar(avatar) {
      return require(`@/assets/avatars/${avatar}`);
    },
    deleteAccount() {
      socket.emit(
        'deleteAccount',
        window.Telegram.WebApp.initData,
        this.newNumber
      );
      this.$router.push('/account');
    },
    showWindowDelete() {
      this.isDelete = true;
    },
    closeWindowDelete() {
      this.isDelete = false;
    },
    saveChanges() {
      socket.emit(
        'updateAccountClickers',
        window.Telegram.WebApp.initData,
        this.newNumber,
        this.accounts
      );
      this.$router.push('/account');
    },
  },
  setup() {
    const accounts = ref([]);

    const toggleDropdown = (index) => {
      accounts.value[index].isDropdownOpen =
        !accounts.value[index].isDropdownOpen;
    };

    const updateActiveCount = (index) => {
      const account = accounts.value[index];
      account.activeCount = account.functions.filter((f) => f.isActive).length;
      account.isActive = account.functions[0].isActive;
    };

    return {
      accounts,
      toggleDropdown,
      updateActiveCount,
    };
  },
});
</script>

<template>
  <account-header />
  <div class="content">
    <p class="accs">{{ $t('account_settings') }}</p>

    <div class="row-info">
      <div class="tg-number">
        <img
          class="tg-icon"
          src="@/assets/tg.svg"
        />
        <p :class="classNumber">{{ displayNumber }}</p>
      </div>
      <button
        class="delete"
        @click="showWindowDelete"
      >
        <img
          class="bin"
          src="@/assets/bin.svg"
        />{{ $t('delete') }}
      </button>
    </div>

    <div class="accounts">
      <div
        v-for="(account, index) in accounts"
        :key="index"
        class="acc"
      >
        <div
          class="acc-content"
          @click="toggleDropdown(index)"
        >
          <div class="acc-left">
            <img
              class="avatar_crypto"
              :src="getAvatar(account.avatar)"
            />
            <p class="name_crypto">{{ account.name }}</p>
          </div>
          <div class="acc-right">
            <div class="status">
              <div
                class="circle-status"
                :style="{
                  backgroundColor: account.isActive ? '#4FD000' : '#FF0000',
                  'box-shadow': `0px 0px 10px 0px ${
                    account.isActive
                      ? 'rgba(79,208,0,0.8)'
                      : 'rgba(255,0,0,0.8)'
                  }`,
                }"
              ></div>
            </div>
            <p class="active">
              {{ $t('active') }} {{ account.activeCount }}/{{
                account.totalCount
              }}
            </p>
            <button class="btn_acc">
              <img
                :class="{ rotated: account.isDropdownOpen }"
                src="@/assets/arrow.svg"
              />
            </button>
          </div>
        </div>
        <div
          v-if="account.isDropdownOpen"
          class="dropdown"
        >
          <div
            v-for="(func, fIndex) in account.functions"
            :key="fIndex"
            class="function-item"
          >
            <span>{{ $t(func.name) }}</span>
            <label class="switch">
              <input
                type="checkbox"
                v-model="func.isActive"
                @change="updateActiveCount(index)"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-btn">
    <button
      class="save-changes"
      @click="saveChanges"
    >
      {{ $t('save_changes') }}
      <img
        class="button-icon"
        src="@/assets/save.svg"
      />
    </button>
    <div class="bloom"></div>
  </div>
  <DeleteAccount
    v-show="isDelete"
    :phone="displayNumber"
    :newNumber="newNumber"
    :deleteAccount="deleteAccount"
    :close="closeWindowDelete"
  />
</template>

<style scoped>
.content {
  height: 80vh;
  padding: 20px;
  overflow-y: hidden;
  touch-action: none !important;
}

.accs {
  color: #fff;
  font-family: 'Mont-SemiBold';
  font-size: 26px;
}

.row-info {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}

.tg-number {
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
}

.tg-icon {
  width: 20px;
  margin-right: 10px;
}

.number {
  font-size: 16px;
  font-family: 'Mont';
  color: rgba(246, 246, 246, 1);
}

.numbers {
  font-size: 14px;
  font-family: 'Mont';
  color: rgba(246, 246, 246, 1);
}

.delete {
  font-family: 'Mont-SemiBold';
  border: 0.5px solid rgba(192, 38, 38, 1);
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  border-radius: 10px;
  font-size: 12px;
  color: rgba(246, 246, 246, 1);
  width: 103px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bin {
  margin-right: 8px;
  width: 10px;
  margin-top: -2px;
}

.accounts {
  height: 60vh;
  margin-top: 20px;
  padding-bottom: 80px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.rotated {
  transform: rotate(90deg);
}

.icon_telegram {
  position: absolute;
  left: 7%;
  top: 20%;
  width: auto;
  height: 3%;
  border-radius: 50%;
}

.acc-content {
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
}
.acc {
  margin-bottom: 10px;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.acc-left {
  display: flex;
  align-items: center;
}

.acc-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar_crypto {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.name_crypto {
  font-family: 'Mont-SemiBold';
  font-size: 13px;
  color: rgba(246, 246, 246, 1);
  margin-left: 15px;
}

.status {
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-status {
  height: 12px;
  width: 12px;
  margin-right: 3.5px;
  border-radius: 50%;
  background-color: #4fd000;
  box-shadow: 0px 0px 10px 0px rgba(79, 208, 0, 1);
}

.active {
  max-width: 60px;
  font-size: 12px;
  color: rgba(246, 246, 246, 0.6);
  font-family: 'Mont-Light';
  margin-right: 15px;
  white-space: nowrap;
}

.btn_acc {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  padding: 0;
  background: rgba(40, 40, 40, 1);
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_acc > img {
  transition: transform 0.5s ease;
}

.btn_acc::after {
  transform: rotate(90deg);
}

.close {
  background-color: rgba(30, 30, 30, 1);
  color: white;
  font-family: 'Mont';
}

.dropdown {
  background: #1e1e1e;
  border-top: 1px solid #323232;
  padding: 10px 15px 10px 60px;
}

.function-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #f6f6f6;
  font-family: 'Mont-Light';
  font-size: 14px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  min-width: 41px;
  height: 23px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1e1e1e;
  transition: 0.4s;
  border-radius: 24px;
  border: solid 0.5px #505050;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: #fa3c00;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider:before {
  transform: translateX(15px);
  background-color: #4fd000;
}

.bottom-btn {
  position: fixed;
  width: -webkit-fill-available;
  bottom: 0;
  margin-left: 20px;
  margin-right: 20px;
  background: #0a0a0a;
  border-radius: 21px 21px 0 0;
}

.save-changes {
  display: flex;
  width: -webkit-fill-available;
  height: 60px;
  z-index: 10;
  font-family: Mont;
  font-size: 100%;
  font-weight: 800;
  color: rgba(30, 30, 30, 1);
  background: #4fd000;
  border: none;
  border-radius: 21px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px -10px 20px 0px #000000;
  transition: background-color 0.2s;
}

.button-icon {
  width: 23px;
  margin-top: -5px;
  margin-left: 16px;
}

.bloom {
  width: 100%;
  height: 50px;
  margin-bottom: -10px;
  background: radial-gradient(circle, rgb(0 255 5 / 20%) 0%, transparent 60%);
  position: relative;
  z-index: 5;
  transition: background 0.2s;
}
</style>
