<script setup>
import { ref, defineProps } from 'vue';
import { useStore } from 'vuex';
import socket from '../../socket';

defineProps({
  userEarn: Number,
});

const store = useStore();

const amount = ref('');
const maxLength = 10;

const setAllUserEarn = (userEarn) => {
  amount.value = '';
  amount.value += userEarn;
};

const addDigit = (digit) => {
  if (amount.value.includes(',')) {
    const decimalPart = amount.value.split(',')[1];
    if (decimalPart.length < 3) {
      amount.value += digit;
    }
  } else if (amount.value.length < maxLength) {
    amount.value += digit;
  }
};

const removeDigit = () => {
  if (amount.value.length > 0) {
    amount.value = amount.value.slice(0, -1);
  }
};

const submitAmount = () => {
  let windowName;

  socket.emit(
    'transferBalance',
    window.Telegram.WebApp.initData,
    +amount.value,
    (success) => {
      if (success) {
        windowName = 'isSuccessWindowVisible';
        store.dispatch('setWindowVisibility', {
          windowName,
          value: 'successfully_translated',
        });
      } else {
        windowName = 'isErrorsWindowVisible';
        store.dispatch('setWindowVisibility', {
          windowName,
          value: 'insufficient_funds',
        });
      }
    }
  );
};
</script>

<template>
  <div class="main">
    <div class="modal">
      <div class="header">
        <p class="status">{{ $t('transfer_to_balance') }}</p>
        <button class="butcross">
          <img
            class="cross"
            @click="$emit('close')"
            src="@/assets/plus.svg"
          />
        </button>
      </div>
      <div class="amount-all">
        <p class="amount">{{ amount ? `${amount}$` : '0$' }}</p>
        <button
          class="all"
          @click="setAllUserEarn(userEarn)"
        >
          {{ $t('all') }}
        </button>
      </div>
      <p class="desc">
        {{ $t('available_withdrawal') }}:
        <span class="bold">{{ userEarn }}$</span>
      </p>

      <div class="calculator">
        <button
          v-for="n in 9"
          :key="n"
          class="k"
          @click="addDigit(n)"
        >
          {{ n }}
        </button>
        <button
          class="k"
          @click="addDigit('0')"
        >
          0
        </button>
        <button
          class="k"
          @click="removeDigit"
        >
          <img
            class="k remove"
            src="@/assets/clear.svg"
          />
        </button>
      </div>

      <div class="center-btn">
        <button
          class="btn-submit"
          :class="{
            disabled: userEarn < +amount || +amount === 0,
          }"
          @click="
            {
              userEarn < +amount || +amount === 0 ? null : submitAmount();
            }
          "
        >
          {{ userEarn < +amount ? $t('insufficient_funds') : $t('confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: #78787880;
  backdrop-filter: blur(15px);
}

.modal {
  width: 80%;
  border-radius: 10px;
  background-color: #1a1a1a;
  padding: 25px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.status {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-SemiBold';
  margin-right: 10px;
}

.butcross {
  width: 27px;
  height: 27px;
  display: flex;
  border-radius: 50%;
  border: 0.5px solid rgba(49, 49, 49, 1);
  background: rgba(40, 40, 40, 1);
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: -10px -10px 0 0;
}

.cross {
  width: 20px;
  height: 20px;
  padding: 5px;
  color: rgba(255, 255, 255, 1);
}

.amount-all {
  margin-top: 10px;
  display: flex;
  border-bottom: 0.5px solid rgba(49, 49, 49, 1);
  justify-content: space-between;
  align-items: center;
}

.amount {
  font-size: 30px;
  color: rgba(246, 246, 246, 1);
  font-family: 'Mont', sans-serif;
}

.all {
  font-family: 'Mont-SemiBold';
  background-color: transparent;
  color: #ffc700;
  border: none;
  border-bottom: 1px solid;
  line-height: 1;
  cursor: pointer;
}

.desc {
  margin-top: 16px;
  font-size: 80%;
  font-family: 'Mont-Light';
  color: rgb(255 255 255 / 80%);
  margin-right: 30px;
}

.bold {
  color: #ffffff;
  font-family: 'Mont';
}

.calculator {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    'a b c'
    'd e f'
    'g h i'
    '. j k';
  gap: 10px;
  margin-bottom: 20px;
}

.calculator button:nth-child(10) {
  grid-area: j;
}

.calculator button:nth-child(11) {
  grid-area: k;
}

.k {
  color: rgba(246, 246, 246, 1);
  font-family: 'Mont-SemiBold', sans-serif;
  font-size: 30px;
  background-color: rgba(34, 34, 34, 1);
  border: none;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
}

.remove {
  margin: 0 auto;
}

.center-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-submit {
  width: -webkit-fill-available;
  border-radius: 15px;
  background-color: rgba(255, 199, 0, 1);
  font-family: 'Mont';
  font-size: 100%;
  height: 54px;
  color: black;
  border: none;
  cursor: pointer;
}

.disabled {
  background-color: rgba(136, 136, 136, 1);
}
</style>
