<script>
export default {
  name: 'SuccessWindow',
  props: {
    success: {
      type: String,
      required: false,
      default: 'success',
    },
  },
};
</script>

<template>
  <div class="main">
    <div class="modal">
      <div class="header">
        <p class="status">{{ $t('success') }}!</p>
        <button class="butcross">
          <img
            class="cross"
            @click="$emit('close')"
            src="@/assets/plus.svg"
          />
        </button>
      </div>

      <div class="action-container">
        <p class="action">{{ $t(success) }}</p>
        <img
          class="status_icon"
          src="@/assets/success.svg"
        />
      </div>

      <div class="center-btn">
        <button
          class="btn-submit"
          @click="$emit('close')"
        >
          {{ $t('close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: #78787880;
  backdrop-filter: blur(15px);
}

.modal {
  width: 80%;
  border-radius: 10px;
  background-color: #1a1a1a;
  padding: 25px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.status {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-SemiBold';
  margin-right: 10px;
}

.butcross {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
  border: 0.5px solid rgba(49, 49, 49, 1);
  background: rgba(40, 40, 40, 1);
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: -10px -10px 0 0;
}

.cross {
  width: 27px;
  padding: 5px;
  color: rgba(255, 255, 255, 1);
}

.action-container {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action {
  font-size: 20px;
  font-family: 'Mont';
  color: #fff;
  margin-right: 16px;
}

.status_icon {
}

.center-btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.btn-submit {
  width: -webkit-fill-available;
  border-radius: 15px;
  background-color: rgba(255, 199, 0, 1);
  font-family: 'Mont';
  font-size: 100%;
  height: 54px;
  color: black;
  border: none;
  cursor: pointer;
}
</style>
