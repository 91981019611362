<script setup>
import { defineProps } from 'vue';
import { useStore } from 'vuex';
import socket from '../../socket';

defineProps({
  userBalance: Number,
  subDays: Number,
});

const store = useStore();

const buySubscription = (userBalance) => {
  let windowName;

  if (userBalance >= 12) {
    socket.emit('buySub', window.Telegram.WebApp.initData, (success) => {
      if (success) {
        windowName = 'isSuccessWindowVisible';
        store.dispatch('setWindowVisibility', {
          windowName,
          value: 'success_renew_sub',
        });
      } else {
        windowName = 'isErrorsWindowVisible';
        store.dispatch('setWindowVisibility', {
          windowName,
          value: 'insufficient_funds',
        });
      }
    });
  }
};

function subscriptionEndDate(subDays) {
  let endDate = new Date();
  endDate.setHours((subDays + 30) * 24);

  return endDate.toLocaleDateString();
}
</script>

<template>
  <div class="main">
    <div class="modal">
      <div class="header">
        <p class="status">{{ $t('buy_sub') }}</p>
        <button class="butcross">
          <img
            class="cross"
            @click="$emit('close')"
            src="@/assets/plus.svg"
          />
        </button>
      </div>

      <p class="desc">{{ $t('will_paid') }}:</p>
      <p class="price">12$</p>
      <p class="desc-date">
        {{ $t('sun_until') }}:
        <span class="date"> {{ subscriptionEndDate(subDays) }}</span>
      </p>
      <div class="center-btn">
        <button
          class="btn-submit"
          :class="{ disabled: userBalance < 12 }"
          @click="buySubscription(userBalance)"
        >
          {{ 12 > userBalance ? $t('insufficient_funds') : $t('confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: #78787880;
  backdrop-filter: blur(15px);
}

.modal {
  width: 80%;
  border-radius: 10px;
  background-color: #1a1a1a;
  padding: 25px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.status {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-SemiBold';
  margin-right: 10px;
}

.butcross {
  width: 27px;
  height: 27px;
  display: flex;
  border-radius: 50%;
  border: 0.5px solid rgba(49, 49, 49, 1);
  background: rgba(40, 40, 40, 1);
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: -10px -10px 0 0;
}

.cross {
  width: 20px;
  height: 20px;
  padding: 5px;
  color: rgba(255, 255, 255, 1);
}

.desc {
  margin-top: 5px;
  font-size: 80%;
  font-family: 'Mont-Light';
  color: rgb(255 255 255 / 80%);
  margin-right: 30px;
}

.price {
  margin-top: 10px;
  color: rgba(255, 199, 0, 1);
  font-family: 'Mont-SemiBold';
  font-size: 200%;
  border-bottom: rgba(35, 35, 35, 1) 1px solid;
}

.desc-date {
  margin-top: 16px;
  font-size: 14px;
  font-family: 'Mont-Light';
  color: rgb(255 255 255 / 80%);
}
.date {
  color: #ffffff;
  font-family: 'Mont';
}

.center-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-submit {
  width: -webkit-fill-available;
  border-radius: 15px;
  background-color: rgba(255, 199, 0, 1);
  font-family: 'Mont';
  font-size: 100%;
  height: 54px;
  color: black;
  border: none;
  cursor: pointer;
}

.disabled {
  background-color: rgba(136, 136, 136, 1);
}
</style>
