// store.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    windows: {
      isErrorWindowVisible: false,
      isPhoneInputWindowVisible: false,
      isCodeInputWindowVisible: false,
      isPasswordInputWindowVisible: false,
      isTopUpWindowVisible: false,
      isBuySubWindowVisible: false,
      isSuccessWindowVisible: false,
      isSlotsWindowVisible: false,
      isTransferWindowVisible: false,
      isWithdrawalWindowVisible: false,
      isReferralLinkInputWindowVisible: false,
      isBuyingReferralsWindowVisible: false,
      isMethodsReplenishmentVisible: false,
    },
    user: {
      name: 'Penguin',
      balance: 0,
      slots: 5,
      lang: 'en',
      firstLogin: 0,
      subDays: 0,
      subUntil: 0,
      ref: {
        code: '',
        count: 0,
        earn: 0,
        earnAll: 0,
      },
    },
    accounts: [],
  },
  mutations: {
    SET_WINDOW_VISIBILITY(state, { windowName, value }) {
      if (value) {
        for (let key in state.windows) {
          if (key !== windowName) {
            state.windows[key] = false;
          }
        }
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
      state.windows[windowName] = value;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_ACCOUNTS(state, accounts) {
      state.accounts = accounts;
    },
  },
  actions: {
    setWindowVisibility({ commit }, payload) {
      commit('SET_WINDOW_VISIBILITY', payload);
    },
    updateUser({ commit }, value) {
      commit('SET_USER', value);
    },
    updateAccounts({ commit }, value) {
      commit('SET_ACCOUNTS', value);
    },
  },
  getters: {
    windows: (state) => state.windows,
    user: (state) => state.user,
    accounts: (state) => state.accounts,
  },
});
