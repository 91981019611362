// router.js
import { createRouter, createWebHistory } from 'vue-router';
import WelcomePage from './components/WelcomePage.vue';
import SettingsPage from './components/SettingsPage.vue';
import SubInactivePage from './components/SubInactivePage.vue';
import AccountsList from './components/AccountsList.vue';
import UserProfile from './components/UserProfile.vue';
import AccountSettings from './components/AccountSettings.vue';
import ShopPage from './components/ShopPage.vue';
import ServerErrorPage from './components/ServerErrorPage.vue';

import { defineAsyncComponent } from 'vue';
import store from './store'; // Assuming you have a Vuex store

const EmptyComponent = { template: '<div></div>' };

const routes = [
  { path: '/', component: EmptyComponent },
  { path: '/next', component: WelcomePage },
  { path: '/settings', component: SettingsPage },
  {
    path: '/test',
    component: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        const component =
          store.state.user.subUntil > Math.floor(Date.now() / 1000)
            ? AccountsList
            : SubInactivePage;
        resolve(component);
      });
    }),
  },
  { path: '/account', component: AccountsList },
  { path: '/inactive', component: SubInactivePage },
  { path: '/profile', component: UserProfile },
  { path: '/shop', component: ShopPage },
  {
    path: '/account/settings/:number',
    name: 'settingsPage',
    component: AccountSettings,
    props: true,
  },
  { path: '/error', component: ServerErrorPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const currentTime = Math.floor(Date.now() / 1000);
  const isSubscriptionExpired = store.state.user.subUntil < currentTime;

  if (to.path === '/account' && isSubscriptionExpired) {
    next('/inactive');
  } else if (to.path === '/inactive' && !isSubscriptionExpired) {
    next('/account');
  } else {
    next();
  }
});

export default router;
