import { createApp, h } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createI18n } from 'vue-i18n';
import messages from './i18n';

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});

const app = createApp({
  render: () => h(App),
});
app.use(i18n);
app.use(router);
app.use(store);
app.mount('#app');
