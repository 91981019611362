<script>
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import socket from '../socket';

export default defineComponent({
  name: 'SettingsPage',
  setup() {
    const store = useStore();
    const userFirstName = store.state.user.name;

    let selectLanguage = store.state.user.lang;

    return {
      userFirstName,
      selectLanguage,
    };
  },
  mounted() {
    window.Telegram.WebApp.BackButton.onClick(() => {
      this.$router.push('/account');
    }).show();
  },
  unmounted() {
    window.Telegram.WebApp.BackButton.hide();
  },
  methods: {
    changeLanguage(language) {
      this.selectLanguage = language;
      console.log('CHANGE: ' + this.selectLanguage);
    },
    saveLanguage() {
      this.$i18n.locale = this.selectLanguage;
      console.log('SAVE: ' + this.selectLanguage);
      socket.emit(
        'changeLanguage',
        window.Telegram.WebApp.initData,
        this.selectLanguage
      );
      this.goHome();
    },
    goHome() {
      this.$router.push('/account');
    },
  },
});
</script>

<template>
  <div id="screen">
    <div class="header">
      <div class="header-avatar">
        <img
          class="avatar"
          src="@/assets/avatar.png"
        />
        <p class="user">{{ userFirstName }}</p>
      </div>
      <button class="butcross">
        <img
          class="cross"
          @click="goHome"
          src="@/assets/plus.svg"
        />
      </button>
    </div>

    <div class="widget-lang">
      <div class="select">{{ $t('select_language') }}</div>

      <label class="custom-radio">
        <input
          type="radio"
          name="radio"
          @change="changeLanguage('ru')"
          :checked="$i18n.locale === 'ru'"
        />
        <span class="checkmark"></span>

        <p class="lang-name">{{ $t('russian') }}</p>
      </label>
      <label class="custom-radio">
        <input
          type="radio"
          name="radio"
          @change="changeLanguage('en')"
          :checked="$i18n.locale === 'en'"
        />
        <span class="checkmark"></span>

        <p class="lang-name">{{ $t('english') }}</p>
      </label>
    </div>

    <div class="btns">
      <button
        class="btn-green"
        @click="saveLanguage"
      >
        {{ $t('save_changes') }}
        <img
          class="save-changes"
          src="@/assets/save.svg"
        />
      </button>

      <button
        class="btn-close"
        @click="goHome"
      >
        {{ $t('close') }}
      </button>
    </div>
  </div>
</template>

<style scoped>
#screen {
  display: flex;
  flex-direction: column;
  height: 93vh;
}

.header {
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: space-between;
}

.header-avatar {
  display: flex;
  align-items: center;
}

.avatar {
  width: 60px;
  height: auto;
  border-radius: 50px;
}

.user {
  cursor: default;
  font-family: 'Mont-SemiBold', sans-serif;
  margin-left: 15px;
  font-size: 26px;
  font-weight: 700;
  color: rgba(246, 246, 246, 1);
}

.butcross {
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50%;
  border: 0.5px solid rgba(49, 49, 49, 1);
  background: rgba(40, 40, 40, 1);
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cross {
  width: 27px;
  padding: 5px;
  color: rgba(255, 255, 255, 1);
}

.widget-lang {
  color: #fff;
  margin: 20px;
  padding: 20px 20px 35px 20px;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  border-radius: 10px;
  border: 0.5px solid #323232;
}

.select {
  font-family: 'Mont';
  font-size: 26px;
  margin-bottom: 20px;
}

.custom-radio {
  display: flex;
  width: min-content;
  position: relative;
  padding-left: 35px;
  margin-right: 20px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #ffc700;
  border-radius: 50%;
}

.custom-radio input:checked ~ .checkmark {
  background-color: transparent;
}

.custom-radio input:checked ~ .checkmark:after {
  content: '';
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: #ffc700;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.lang-name {
  font-family: 'Mont-Light';
  font-size: 18px;
}

.btns {
  margin-top: auto;
}

.btn-green {
  display: flex;
  width: -webkit-fill-available;
  height: 56px;
  font-family: Mont;
  font-size: 100%;
  font-weight: 800;
  color: rgba(30, 30, 30, 1);
  background: rgba(79, 208, 0, 1);
  box-shadow: none;
  border: none;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 20px;
}

.save-changes {
  width: 22px;
  margin-top: -5px;
  margin-left: 16px;
}

.btn-close {
  font-family: 'Mont-Light';
  margin: 10px 20px 20px 20px;
  font-size: 130%;
  font-weight: 800;
  text-align: center;
  width: -webkit-fill-available;
  height: 56px;
  border-radius: 15px;
  border: 1px solid rgba(65, 65, 65, 1);
  background-color: rgba(10, 10, 10, 1);
  color: rgba(246, 246, 246, 1);
  cursor: pointer;
}
</style>
