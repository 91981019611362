<script setup>
import { ref, defineProps } from 'vue';
import { useStore } from 'vuex';
import socket from '../../socket';

defineProps({
  userEarn: Number,
});

const store = useStore();

const amount = ref(10);
const address = ref('');

const setAllUserEarn = (userEarn) => {
  amount.value = 0;
  amount.value = userEarn;
};

const submitAmount = (address) => {
  let windowName;

  socket.emit(
    'withdrawEarn',
    window.Telegram.WebApp.initData,
    +amount.value,
    address,
    (success) => {
      if (success) {
        windowName = 'isSuccessWindowVisible';
        store.dispatch('setWindowVisibility', {
          windowName,
          value: 'application_created',
        });
      } else {
        windowName = 'isErrorsWindowVisible';
        store.dispatch('setWindowVisibility', {
          windowName,
          value: 'insufficient_funds',
        });
      }
    }
  );
};

const insertText = () => {
  window.navigator.clipboard.readText().then((text) => {
    address.value = text;
  });
};
</script>

<template>
  <div class="main">
    <div class="modal">
      <div class="header">
        <p class="status">{{ $t('withdraw_usdt') }}</p>
        <button class="butcross">
          <img
            class="cross"
            @click="$emit('close')"
            src="@/assets/plus.svg"
          />
        </button>
      </div>

      <div class="amount-all">
        <div class="amount-input">
          <input
            class="amount"
            type="number"
            name="number"
            size="10"
            v-model="amount"
          />
          <img
            src="@/assets/dollar.svg"
            class="dol"
          />
        </div>
        <button
          class="all"
          @click="setAllUserEarn(userEarn)"
        >
          {{ $t('all') }}
        </button>
      </div>
      <p class="amount-min">{{ $t('output_from') }} 10$</p>

      <p class="desc">{{ $t('address_usdt') }}</p>

      <div class="input-container">
        <input
          type="text"
          name="text"
          class="address"
          v-model="address"
        />
        <button
          class="insert"
          @click="insertText(address)"
        >
          {{ $t('paste') }}
        </button>
      </div>

      <p class="desc-date">
        {{ $t('abount_withdraw') }}
      </p>
      <div class="center-btn">
        <button
          class="btn-submit"
          :class="{
            disabled:
              +amount < 10 || userEarn < +amount || address.length === 0,
          }"
          @click="
            {
              +amount < 10 || userEarn < +amount || address.length === 0
                ? null
                : submitAmount(address);
            }
          "
        >
          {{
            +amount > userEarn
              ? $t('insufficient_funds')
              : address.length === 0
              ? $t('enter_the_address')
              : $t('confirm')
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal {
  width: 80%;
  border-radius: 16px;
  background-color: rgba(26, 26, 26, 1);
  padding: 25px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.status {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-SemiBold';
  margin-right: 10px;
}

.butcross {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
  border: 0.5px solid rgba(49, 49, 49, 1);
  background: rgba(40, 40, 40, 1);
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: -10px -10px 0 0;
}

.cross {
  width: 27px;
  padding: 5px;
  color: rgba(255, 255, 255, 1);
}

.amount-all {
  margin-top: 10px;
  display: flex;
  border-bottom: 0.5px solid rgba(49, 49, 49, 1);
  justify-content: space-between;
  align-items: center;
}

.all {
  font-family: 'Mont-SemiBold';
  background-color: transparent;
  color: #ffc700;
  border: none;
  border-bottom: 1px solid;
  line-height: 1;
  cursor: pointer;
}

.amount-input {
  display: flex;
}

.dol {
  width: 16px;
  padding-bottom: 5px;
}

.amount {
  width: 90%;
  margin: 0;
  font-size: 30px;
  color: rgba(246, 246, 246, 1);
  font-family: 'Mont', sans-serif;
  border: none;
  outline: none;
  background-color: transparent;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.amount-min {
  font-size: 14px;
  font-family: 'Mont-Light';
  color: rgb(255 255 255 / 70%);
}

.desc {
  margin-top: 20px;
  font-family: 'Mont-SemiBold';
  color: white;
  margin-right: 30px;
}

.input-container {
  border-bottom: 0.5px solid rgba(49, 49, 49, 1);
  display: flex;
  align-items: baseline;
}

.address {
  margin-top: 16px;
  border: none;
  font-size: 16px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  color: #fff;
  font-family: 'Mont-Light';
  font-size: 14px;
}

.address:focus {
  border-bottom-color: #fff;
}

.insert {
  border: none;
  background-color: transparent;
  font-family: 'Mont';
  color: white;
  padding: 8px 0 8px 8px;
  opacity: 0.8;
  cursor: pointer;
  font-size: 16px;
  outline: none;
}

.desc-date {
  margin-top: 16px;
  font-size: 14px;
  font-family: 'Mont-Light';
  color: rgb(255 255 255 / 70%);
}

.center-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-submit {
  width: -webkit-fill-available;
  border-radius: 15px;
  background-color: rgba(255, 199, 0, 1);
  font-family: 'Mont';
  font-size: 100%;
  height: 54px;
  color: black;
  border: none;
  cursor: pointer;
}

.disabled {
  background-color: rgba(136, 136, 136, 1);
}
</style>
