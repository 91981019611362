<script setup>
import { defineProps, ref } from 'vue';

defineProps({
  title: String,
  close: null,
  openBuy: null,
});

const link = ref('');
</script>

<template>
  <div class="main">
    <div class="modal">
      <div class="header">
        <p class="status">{{ $t('enter_the_referral_link') }}</p>
        <button class="btn-cross">
          <img
            class="cross"
            @click="close"
            src="@/assets/plus.svg"
          />
        </button>
      </div>

      <p class="desc">{{ $t('for_the_game') }}</p>
      <p class="title-game">{{ title }}</p>

      <p class="desc">{{ $t('referral_link_for_cheating') }}</p>
      <input
        type="text"
        name="text"
        v-model="link"
        placeholder="t.me/game?start=referral_1099532...."
        class="input-link"
      />

      <div class="btns">
        <button
          class="btn-confirm"
          :class="{
            disabled: link.length <= 0,
          }"
          @click="openBuy(link)"
        >
          {{ link.length > 0 ? $t('confirm') : $t('enter_the_referral_link') }}
        </button>
        <button
          class="btn-close"
          @click="close"
        >
          {{ $t('close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: #78787880;
  backdrop-filter: blur(15px);
}

.modal {
  width: 80%;
  border-radius: 10px;
  background-color: #1a1a1a;
  padding: 25px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.status {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-SemiBold';
  margin-right: 10px;
}

.btn-cross {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
  border: 0.5px solid rgba(49, 49, 49, 1);
  background: rgba(40, 40, 40, 1);
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: -10px -10px 0 0;
}

.cross {
  width: 27px;
  padding: 5px;
  color: rgba(255, 255, 255, 1);
}

.desc {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 17.89px;
  font-family: 'Mont-SemiBold';
  color: rgb(255 255 255 / 50%);
}

.title-game {
  font-size: 12px;
  font-family: 'Mont-SemiBold';
  color: white;
}

.input-link {
  width: 100%;
  padding: 8px;
  background-color: #111111;
  border: 0.5px solid #323232;
  border-radius: 7px;
  outline: none;
  color: #f6f6f6;
  font-family: 'Mont';
  font-size: 12px;
  font-weight: 700;
  line-height: 15.34px;
}

.btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}

.btn-confirm {
  border-radius: 15px;
  background-color: rgba(255, 199, 0, 1);
  font-family: 'Mont';
  font-size: 100%;
  height: 54px;
  color: black;
  border: none;
  cursor: pointer;
}

.disabled {
  background-color: rgba(136, 136, 136, 1);
}

.btn-close {
  margin-top: 10px;
  border-radius: 15px;
  background-color: transparent;
  font-family: 'Mont';
  font-size: 100%;
  height: 54px;
  color: white;
  border: 0.5px solid rgba(49, 49, 49, 1);
  cursor: pointer;
}
</style>
