<!-- AnimatedButton.vue -->
<template>
  <button @click="animateClick" :class="{ clicked: isClicked }" :style="{'font-family': 'Mont-Semibold'}" @animationend="resetAnimation">
   <slot></slot>
  </button>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AnimatedButton',
  data() {
    return {
      isClicked: false,
    };
  },
  methods: {
    animateClick() {
      this.isClicked = true;
    },
    resetAnimation() {
      this.isClicked = false;
    },
  },
});
</script>

