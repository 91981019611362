<script>
import { defineComponent } from 'vue';
import AccountHeader from './views/AccountHeader.vue';
import ProductCard from './views/ProductCard.vue';

export default defineComponent({
  name: 'AccountsList',
  components: {
    AccountHeader,
    ProductCard,
  },
});
</script>

<template>
  <div class="screen">
    <AccountHeader />
    <div class="shop-header">
      <div class="shop-header_content">
        <p class="shop-header_content_title">{{ $t('shop') }}</p>
        <p class="shop-header_content_desc">
          {{ $t('shop_desc') }}
        </p>
      </div>
      <button class="shop-header_btn-close">
        <img
          class="shop-header_btn-close_img"
          @click="this.$router.push('/account')"
          src="@/assets/plus.svg"
        />
      </button>
    </div>
    <div class="shop_content">
      <ProductCard
        imgName="blum.png"
        title="Blum"
      />
      <ProductCard
        imgName="beer.png"
        title="Beer"
      />
      <ProductCard
        imgName="hamster.png"
        title="Hamster Kombat"
      />
      <ProductCard
        imgName="iceberg.png"
        title="Iceberg"
      />
      <ProductCard
        imgName="memefi.png"
        title="Memefi"
      />
      <ProductCard
        imgName="pixel.png"
        title="Pixel"
      />
      <ProductCard
        imgName="vertus.png"
        title="Vertus"
      />
      <ProductCard
        imgName="yescoin.png"
        title="Yescoin"
      />
    </div>
  </div>
</template>

<style scoped>
.screen {
  height: 100vh;
  overflow: hidden;
  touch-action: none !important;
}

.shop-header {
  padding: 10px;
  display: flex;
}

.shop-header_content {
  display: flex;
  flex-direction: column;
  font-family: 'Mont';
  font-weight: 700;
  color: white;
}

.shop-header_content_title {
  font-size: 30px;
  line-height: 38.34px;
}

.shop-header_content_desc {
  font-size: 12px;
  line-height: 15.34px;
  opacity: 0.8;
}

.shop-header_btn-close {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
  border: 0.5px solid rgba(49, 49, 49, 1);
  background: rgba(40, 40, 40, 1);
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.shop-header_btn-close_img {
  width: 27px;
  padding: 5px;
  color: rgba(255, 255, 255, 1);
}

.shop_content {
  height: 80vh;
  padding-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
