<script>
import MethodsReplenishment from '../modals/MethodsReplenishment.vue';
import BalanceTopUp from '../modals/BalanceTopUp.vue';

export default {
  name: 'BtnSubscription',
  props: {
    isBcgGreen: Boolean,
  },
  components: {
    BalanceTopUp,
    MethodsReplenishment,
  },
  data() {
    return {
      isShowMethods: false,
      isShowTopUp: false,
      paymentMethod: 0,
    };
  },
  methods: {
    showMethods() {
      this.isShowMethods = true;
    },
    hideMethods() {
      this.isShowMethods = false;
    },
    showTopUp(paymentMethod) {
      this.hideMethods();
      this.paymentMethod = paymentMethod;
      this.isShowTopUp = true;
    },
    hideTopUp() {
      this.isShowTopUp = false;
    },
  },
};
</script>

<template>
  <button
    class="btn"
    :class="{ background_green: isBcgGreen }"
    @click="showMethods"
  >
    {{ $t('topup_balance') }}
    <img
      class="add_balance"
      src="@/assets/plusBalance.svg"
    />
  </button>

  <MethodsReplenishment
    v-show="isShowMethods"
    :hide="hideMethods"
    :showTopUp="showTopUp"
  />
  <BalanceTopUp
    v-show="isShowTopUp"
    :hide="hideTopUp"
    :paymentMethod="paymentMethod"
  />
</template>

<style scoped>
.btn {
  display: flex;
  width: 100%;
  height: 8vh;
  font-family: Mont;
  font-size: 100%;
  color: rgba(30, 30, 30, 1);
  background-color: rgba(255, 199, 0, 1);
  box-shadow: none;
  border: none;
  border-radius: 21px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.background_green {
  width: 98%;
  margin: 4px;
  background-color: rgba(79, 208, 0, 1);
  border-radius: 15px;
}

.add_balance {
  width: 27px;
  margin-left: 10px;
}
</style>
