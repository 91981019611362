<script>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AccountHeader',
  setup() {
    const store = useStore();

    const subscriptionStatus = computed(
      () => store.state.user.subUntil > Math.floor(Date.now() / 1000)
    );

    const userFirstName = computed(() => store.state.user.name);
    const subUntil = computed(() => store.state.user.subUntil);
    const langUser = computed(() => store.state.user.lang);

    return {
      subscriptionStatus,
      userFirstName,
      subUntil,
      langUser,
    };
  },
  methods: {
    goToSettings() {
      this.$router.push('/settings');
    },
    goToProfile() {
      this.$router.push('/profile');
    },
    goToShop() {
      const windowName = 'isErrorWindowVisible';
      this.$store.dispatch('setWindowVisibility', {
        windowName,
        value: 'page_in_dev',
      });
      // this.$router.push('/shop');
    },
    subscriptionEndDate(subUntil) {
      let endDate = new Date(subUntil * 1000);

      const localDate = new Intl.DateTimeFormat(this.langUser).format(endDate);

      return localDate;
    },
  },
});
</script>

<template>
  <header class="header">
    <div class="container">
      <img
        class="avatar"
        src="@/assets/avatar.png"
        alt="Avatar"
        @click="goToProfile"
      />
      <div class="info_user">
        <p
          class="username"
          @click="goToProfile"
        >
          {{ userFirstName }}
        </p>
        <p
          class="subscription"
          :class="{ 'subscription-inactive': !subscriptionStatus }"
        >
          {{
            subscriptionStatus
              ? $t('subscription_is_active')
              : $t('subscription_is_not_active')
          }}
          <span class="subscription_date">
            {{ subscriptionStatus ? subscriptionEndDate(subUntil) : '' }}
          </span>
        </p>
      </div>
      <div class="header__buttons">
        <button
          class="wallet"
          @click="goToShop"
        >
          <img
            class="icon"
            src="@/assets/wallet.svg"
            alt="Wallet"
          />
        </button>
        <button
          class="settings"
          @click="goToSettings"
        >
          <img
            class="icon"
            src="@/assets/settings.svg"
            alt="Settings"
          />
        </button>
      </div>
    </div>
  </header>
</template>

<style scoped>
.header {
  width: -webkit-fill-available;
  background-color: rgba(10, 10, 10, 1);
  border-bottom: 0.5px solid rgb(151, 151, 151, 0.2);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.avatar {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  cursor: pointer;
}

.info_user {
  max-width: 100%;
  margin-right: auto;
  margin-left: 10px;
}

.username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Mont-SemiBold', sans-serif;
  font-size: 14px;
  color: rgba(246, 246, 246, 1);
  cursor: pointer;
}

.subscription {
  opacity: 80%;
  font-size: 12px;
  font-family: 'Mont-Light', sans-serif;
  color: rgba(246, 246, 246, 1);
}

.subscription-inactive {
  color: red;
}

.subscription_date {
  font-size: 12px;
  font-family: 'Mont-SemiBold', sans-serif;
}

.header__buttons {
  display: flex;
}

.wallet,
.settings {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  border: 0.5px solid rgba(50, 50, 50, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.settings {
  margin-left: 10px;
}

.icon {
  width: 20px;
}
</style>
