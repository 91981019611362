<template>
  <div class="screen">
    <img
      class="img-error"
      src="@/assets/error.png"
    />
    <p class="header-error">{{ $t('server_connection_error') }}</p>
    <p class="desc-error">
      {{ $t('server_connection_error_desc') }}
    </p>
  </div>
</template>

<style scoped>
.screen {
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Mont-SemiBold', sans-serif;
}

.img-error {
  width: 100px;
  height: 90px;
}

.header-error {
  margin-top: 30px;
  font-size: 26px;
  color: rgba(246, 246, 246, 1);
}

.desc-error {
  margin-top: 10px;
  padding: 0 15px;
  opacity: 80%;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
}
</style>
