<script>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import AccountHeader from './views/AccountHeader.vue';
import BtnSubscription from './views/BtnTopUp.vue';

export default defineComponent({
  name: 'SubInactivePage',
  components: {
    AccountHeader,
    BtnSubscription,
  },
  setup() {
    const store = useStore();
    const showBalanceTopUp = computed(() => store.state.isTopUpWindowVisible);
    const showBuySub = computed(() => store.state.isBuySubWindowVisible);

    const userBalance = computed(() => store.state.user.balance);

    return {
      showBalanceTopUp,
      showBuySub,
      userBalance,
    };
  },
  methods: {
    openTopUpWindow() {
      this.$store.state.isTopUpWindowVisible = true;
    },
    openBuySubWindow() {
      this.$store.state.isBuySubWindowVisible = true;
    },
  },
});
</script>

<template>
  <div class="main">
    <account-header />
    <div class="image-container">
      <img
        src="@/assets/sadPenguin.png"
        class="main_png"
      />
      <div class="border3"></div>
    </div>

    <div class="main_text">{{ $t('sub_false') }}</div>
    <div class="text">{{ $t('for_start_need') }}</div>

    <div class="widgets">
      <div class="widget">
        <div class="dollars">
          <img
            src="@/assets/dollar.svg"
            class="dol"
          />
          <p class="widget-value">{{ userBalance }}</p>
        </div>
        <p class="widget-desc">{{ $t('balance') }}</p>
      </div>
      <div class="widget">
        <div class="dollars">
          <img
            src="@/assets/dollar.svg"
            class="dol"
          />
          <p class="widget-value">12</p>
        </div>
        <p class="widget-desc">{{ $t('sub_cost') }}</p>
      </div>
    </div>
    <div class="btns">
      <BtnSubscription :isBcgGreen="false" />
      <button
        class="butn2"
        @click="$emit('openModal', 'isBuySubWindowVisible')"
      >
        {{ $t('buy_sub') }}
        <img
          class="buy_sub"
          src="@/assets/buySub.svg"
        />
      </button>
    </div>
  </div>
</template>

<style scoped>
.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.image-container {
  position: relative;
  width: 45%;
  max-width: 300px;
  margin: 30px auto;
}

.image-container::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.main_png {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  object-fit: cover;
  z-index: 2;
}

.border3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(250, 60, 0, 0.8);
  z-index: 1;
  box-shadow: 0 0 15px 5px rgba(250, 60, 0, 0.5);
  animation: glowingEffect 2s infinite alternate;
}

@keyframes glowingEffect {
  from {
    box-shadow: 0 0 15px 5px rgba(250, 60, 0, 0.5);
  }
  to {
    box-shadow: 0 0 25px 10px rgba(250, 60, 0, 0.8);
  }
}

.main_text {
  width: 85%;
  margin: 0 auto;
  font-family: Mont;
  font-size: 150%;
  font-weight: 800;
  line-height: 1.2;
  text-align: center;
  color: rgba(246, 246, 246, 1);
}

.text {
  width: 95%;
  margin: 10px auto;
  font-family: Mont-SemiBold;
  font-size: 90%;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  color: rgb(193, 188, 188);
}

.widgets {
  display: flex;
  justify-content: space-between;
  margin: 10px 15px;
}

.widget {
  display: flex;
  width: 50%;
  margin: 4px;
  padding: 0 8px;
  border-radius: 10px;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  border: 0.5px solid rgba(50, 50, 50, 0.3);
  flex-direction: column;
  justify-content: space-between;
}

.dollars {
  display: flex;
  align-items: center;
}

.dol {
  width: 9px;
}

.widget-value {
  font-family: Mont-SemiBold;
  font-size: 20px;
  margin-left: 6px;
  color: rgba(246, 246, 246, 1);
}

.widget-desc {
  font-family: Mont-Light;
  font-size: 75%;
  font-weight: 600;
  margin: 7px 0;
  padding-right: 10px;
  color: rgba(151, 151, 151, 1);
}

.btns {
  margin: 10px 15px;
}

.buy-sub-btn {
  background: rgba(79, 208, 0, 1);
}

.butn2 {
  display: flex;
  width: 100%;
  height: 8vh;
  margin-top: 10px;
  font-family: Mont;
  font-size: 100%;
  font-weight: 800;
  color: rgba(30, 30, 30, 1);
  background: rgba(79, 208, 0, 1);
  box-shadow: none;
  border: none;
  border-radius: 21px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add_balance {
  width: 23px;
  margin-left: 10px;
}

.buy_sub {
  width: 22px;
  margin-left: 10px;
}
</style>
