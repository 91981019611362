<script>
import { mapState } from 'vuex';
import BtnSubscription from './views/BtnTopUp.vue';

export default {
  name: 'UserProfile',
  components: {
    BtnSubscription,
  },
  data() {
    return {
      showCopiedMessage: false,
      showCopiedMessageFlow: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  mounted() {
    window.Telegram.WebApp.BackButton.onClick(() => {
      this.$router.push('/account');
    }).show();
  },
  unmounted() {
    window.Telegram.WebApp.BackButton.hide();
  },
  methods: {
    closeProfile() {
      this.$router.push('/account');
    },
    copyToClipboard() {
      const textToCopy =
        'https://t.me/penguinminerbot?start=' + this.user.ref.code;
      navigator.clipboard.writeText(textToCopy).then(() => {
        this.showCopiedMessageFlow = true;
        this.showCopiedMessage = true;
        setTimeout(() => {
          this.showCopiedMessage = false;
        }, 2000);
        setTimeout(() => {
          this.showCopiedMessageFlow = false;
        }, 2500);
      });
    },
  },
};
</script>

<template>
  <div class="screen">
    <div class="header">
      <div class="header-avatar">
        <img
          class="header-avatar_img"
          src="@/assets/avatar.png"
        />
        <p class="header_user-name">{{ user.name }}</p>
      </div>
      <button class="header_btn-close">
        <img
          class="header_btn-close_img"
          @click="closeProfile"
          src="@/assets/plus.svg"
        />
      </button>
    </div>

    <div class="widgets">
      <div
        class="widget"
        style="background: linear-gradient(44deg, #1d3801 -50%, #1e1e1e 71.65%)"
      >
        <div
          class="widget-icon"
          style="align-items: flex-start"
        >
          <img
            src="@/assets/dollar.svg"
            class="dol"
          />
          <p
            class="widget-value"
            style="color: rgba(79, 208, 0, 1)"
          >
            {{ user.balance }}
          </p>
        </div>
        <p class="widget-desc">{{ $t('balance') }}</p>
      </div>
      <div
        class="widget"
        style="background: linear-gradient(44deg, #433200 -50%, #1e1e1e 71.65%)"
      >
        <div class="widget-icon">
          <img
            src="@/assets/sub.svg"
            class="sub"
          />
          <p
            class="widget-value"
            style="color: rgba(255, 199, 0, 1)"
          >
            {{ user.subDays }} {{ $t('days') }}.
          </p>
        </div>
        <p class="widget-desc">{{ $t('sub') }}</p>
      </div>
      <div
        class="widget"
        style="background: linear-gradient(44deg, #012345 -50%, #1e1e1e 71.65%)"
      >
        <div class="widget-icon">
          <img
            src="@/assets/slot.svg"
            class="slot"
          />
          <p
            class="widget-value"
            style="color: rgba(0, 148, 255, 1)"
          >
            {{ user.slots }}
          </p>
        </div>
        <p class="widget-desc">{{ $t('slots') }}</p>
      </div>
    </div>

    <div class="yellow-btns">
      <button
        class="btn-yellow"
        @click="$emit('openModal', 'isBuySubWindowVisible')"
        style="margin-right: 8px"
      >
        {{ $t('sub_renew') }}
      </button>
      <button
        class="btn-yellow"
        @click="$emit('openModal', 'isSlotsWindowVisible')"
      >
        {{ $t('add_slots') }}
      </button>
    </div>

    <BtnSubscription :isBcgGreen="true" />

    <div class="ref">{{ $t('ref_system') }}</div>

    <button
      class="ref_link"
      @click="copyToClipboard"
    >
      <p>t.me/penguinminerbot?start={{ user.ref.code }}</p>
      <img
        class="copy"
        src="@/assets/copy.svg"
      />
    </button>

    <div
      v-if="showCopiedMessageFlow"
      :class="{ 'copied-message': true, 'show-message': showCopiedMessage }"
    >
      {{ $t('link_copied') }}
    </div>

    <div class="stats">
      {{ $t('invited') }}: <span class="bold">{{ user.ref.count }}</span
      ><br />
      {{ $t('available_withdraw') }}:
      <span class="bold">{{ user.ref.earn }}$</span><br />
      {{ $t('earn_all') }}: <span class="bold">{{ user.ref.earnAll }}$</span
      ><br />
    </div>

    <div class="balance-btns">
      <button
        class="btn-gray"
        @click="$emit('openModal', 'isTransferWindowVisible')"
        style="margin-right: 8px"
      >
        {{ $t('transfer_to_balance') }}
      </button>
      <button
        class="btn-gray"
        @click="$emit('openModal', 'isWithdrawalWindowVisible')"
      >
        {{ $t('withdraw_usdt') }}
      </button>
    </div>

    <button
      class="btn-close"
      @click="closeProfile"
    >
      {{ $t('close') }}
    </button>
  </div>
</template>

<style scoped>
.screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 10px;
}

.header {
  display: flex;
  height: 10vh;
  margin: 4px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}

.header-avatar {
  display: flex;
  align-items: center;
}

.header-avatar_img {
  width: 60px;
  height: auto;
  border-radius: 50px;
}

.header_user-name {
  cursor: default;
  font-family: 'Mont-SemiBold', sans-serif;
  margin-left: 15px;
  font-size: 26px;
  font-weight: 700;
  color: rgba(246, 246, 246, 1);
}

.header_btn-close {
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50%;
  border: 0.5px solid rgba(49, 49, 49, 1);
  background: rgba(40, 40, 40, 1);
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.header_btn-close_img {
  width: 27px;
  padding: 5px;
  color: rgba(255, 255, 255, 1);
}

.widgets {
  display: flex;
  flex: 30% 40% 30%;
  height: 13vh;
}

.widget {
  flex-grow: 1;
  display: flex;
  padding: 10px;
  margin: 4px;
  border-radius: 10px;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  border: 0.5px solid rgba(50, 50, 50, 1);
  flex-direction: column;
  justify-content: space-between;
  cursor: default;
}

.widget-icon {
  display: flex;
  align-items: baseline;
}

.dol {
  width: 8px;
  margin: auto 0 5px 0;
}

.sub {
  width: 14px;
}

.slot {
  width: 15px;
}

.widget-value {
  font-family: Mont-SemiBold;
  font-size: 19px;
  line-height: 24px;
  margin-left: 7px;
  color: rgba(246, 246, 246, 1);
}

.widget-desc {
  font-family: Mont-Light;
  font-size: 12px;
  font-weight: 600;
  padding-right: 10px;
  color: rgba(151, 151, 151, 1);
}

.yellow-btns {
  display: flex;
  height: 7vh;
  margin: 4px;
}

.yellow-btns :first-child {
  width: 55%;
}

.yellow-btns :last-child {
  width: 45%;
}

.btn-yellow {
  font-family: Mont;
  font-size: 75%;
  text-align: center;
  border-radius: 12px;
  border: 1px;
  background: rgba(255, 199, 0, 1);
  color: rgba(26, 26, 26, 1);
  cursor: pointer;
}

.btn-green {
  display: flex;
  height: 8vh;
  margin: 4px;
  font-family: Mont;
  font-size: 100%;
  color: rgba(30, 30, 30, 1);
  background: rgba(79, 208, 0, 1);
  box-shadow: none;
  border: none;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add_balance {
  width: 27px;
  margin-left: 10px;
}

.ref {
  height: 5vh;
  margin-top: 10px;
  text-align: center;
  font-family: Mont;
  font-size: 25px;
  color: rgba(246, 246, 246, 1);
}

.ref_link {
  margin: 4px;
  margin-top: 10px;
  height: 7vh;
  padding: 16px;
  font-family: Mont;
  text-align: left;
  color: rgb(170 168 168);
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  border-radius: 10px;
  border: 0.3px solid rgba(50, 50, 50, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.ref_link > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy {
  width: 26px;
  margin-left: 10px;
  color: rgba(246, 246, 246, 1);
}

.stats {
  height: 12vh;
  margin: 4px;
  margin-top: 10px;
  font-family: 'Mont-Light';
  line-height: 1.7;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: rgba(135, 135, 135, 1);
  cursor: default;
}

.balance-btns {
  display: flex;
  height: 7vh;
  margin: 4px;
}

.balance-btns :first-child {
  width: 55%;
}

.balance-btns :last-child {
  width: 45%;
}

.btn-gray {
  font-family: 'Mont-Light';
  font-size: 75%;
  border-radius: 15px;
  border: 0.3px solid rgba(50, 50, 50, 0.5);
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  color: rgba(246, 246, 246, 1);
  cursor: pointer;
}

.btn-close {
  margin: 20px 0 0 0;
  font-family: 'Mont-Light';
  font-size: 120%;
  font-weight: 900;
  text-align: center;
  height: 8vh;
  border-radius: 15px;
  border: 1px solid rgba(65, 65, 65, 1);
  background-color: rgba(10, 10, 10, 1);
  color: rgba(246, 246, 246, 1);
  cursor: pointer;
}

.bold {
  font-family: Mont;
  font-size: 16px;
  font-weight: 800;
  color: rgba(246, 246, 246, 1);
}

.copied-message {
  position: fixed;
  font-family: Mont;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #5f5f5f;
  color: white;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.5s;
}

.show-message {
  opacity: 1;
}
</style>
