<script setup>
import { defineProps, ref, computed } from 'vue';
import { useStore } from 'vuex';
import ReferralLinkInput from '../modals/ReferralLinkInput.vue';
import BuyingReferrals from '../modals/BuyingReferrals.vue';
import SuccesWindowBuyRef from '../modals/SuccesWindowBuyRef.vue';

defineProps({
  title: String,
  imgName: String,
});

const userBalance = computed(() => useStore().state.user.balance);

const isShowLink = ref(false);
const isShowBuy = ref(false);
const isShowSucces = ref(false);

const refCount = ref(1);
const refPrice = 0.2;

function incrementRef() {
  refCount.value++;
}
function decrementRef() {
  if (refCount.value > 1) {
    refCount.value--;
  }
}
function total() {
  return (refCount.value * refPrice).toFixed(1);
}

function openLink() {
  isShowLink.value = true;
}

function openBuy(link) {
  if (link.length > 0) {
    closeLink();
    isShowBuy.value = true;
  }
}

function openSucces() {
  closeBuy();
  isShowSucces.value = true;
}

function closeLink() {
  isShowLink.value = false;
}

function closeBuy() {
  isShowBuy.value = false;
}

function closeSucces() {
  isShowSucces.value = false;
}
</script>

<template>
  <div class="product-card">
    <img
      class="product-card_img"
      :src="require(`@/assets/avatars/${imgName}`)"
    />
    <p class="product-card_title">{{ title }}</p>
    <div class="product-card_action">
      <!-- <button
        class="product-card_action_minus"
        @click="decrementRef"
      >
        -
      </button> -->

      <img
        src="@/assets/subtract.png"
        @click="decrementRef"
        class="product-card_action_minus"
      />

      <p class="product-card_action_count">{{ refCount }}</p>

      <!-- <button
        class="product-card_action_plus"
        @click="incrementRef"
      >
        +
      </button> -->
      <img
        src="@/assets/add.png"
        @click="incrementRef"
        class="product-card_action_plus"
      />
    </div>
    <p class="product-card_total-amount">{{ $t('total') }}: {{ total() }}$</p>
    <button
      class="product-card_btn-buy"
      @click="openLink"
    >
      {{ $t('buy') }}
    </button>
  </div>

  <ReferralLinkInput
    v-show="isShowLink"
    :title="title"
    :close="closeLink"
    :openBuy="openBuy"
  />
  <BuyingReferrals
    v-show="isShowBuy"
    :userBalance="userBalance"
    :title="title"
    :count="refCount"
    :price="total()"
    :openSucces="openSucces"
    :close="closeBuy"
  />

  <SuccesWindowBuyRef
    v-show="isShowSucces"
    :count="refCount"
    :close="closeSucces"
  />
</template>

<style scoped>
.product-card {
  margin: 20px 5px;
  width: 45vw;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  border-radius: 10px;
  border: 0.5px solid rgba(50, 50, 50, 1);
}

.product-card_img {
  width: 48px;
  height: 48px;
  border-radius: 10px;
  border: 0.5px solid rgba(50, 50, 50, 1);
}

.product-card_title {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 800;
  line-height: 15.34px;
  font-family: 'Mont';
  color: white;
}

.product-card_action {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.product-card_action_minus {
  /* display: flex;
  align-items: center;
  justify-content: center;
  border: none; */
  height: 14px;
  width: 14px;
  /* font-size: 15px;
  font-weight: 900;
  border-radius: 50%; */
  cursor: pointer;
  /* color: black; */
}

.product-card_action_count {
  width: 70px;
  text-align: center;
  font-family: 'Mont';
  font-size: 30px;
  line-height: 38.34px;
  color: rgba(255, 255, 255, 1);
}

.product-card_action_plus {
  /* display: flex;
  align-items: center;
  justify-content: center;
  border: none; */
  height: 14px;
  width: 14px;
  /* font-size: 15px;
  font-weight: 900;
  border-radius: 50%; */
  cursor: pointer;
  /* color: black; */
}

.product-card_total-amount {
  width: 100px;
  margin-bottom: 10px;
  text-align: center;
  font-family: 'Mont';
  line-height: 15.34px;
  font-weight: 700;
  font-size: 12px;
  color: white;
}

.product-card_btn-buy {
  width: 75%;
  padding: 5px;
  font-family: 'Mont';
  font-size: 12px;
  font-weight: 800;
  color: rgba(30, 30, 30, 1);
  background-color: rgba(255, 199, 0, 1);
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
</style>
