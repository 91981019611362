<template>
  <div id="app">
    <router-view @openModal="openModal" />

    <withdraw-u-s-d-t
      v-if="windows.isWithdrawalWindowVisible"
      @close="closeModal('isWithdrawalWindowVisible')"
      @open="openModal"
      :userEarn="user.ref.earn"
    >
    </withdraw-u-s-d-t>

    <balance-transfer
      v-if="windows.isTransferWindowVisible"
      @close="closeModal('isTransferWindowVisible')"
      @open="openModal"
      :userEarn="user.ref.earn"
    >
    </balance-transfer>

    <delete-account
      v-if="windows.isDeleteWindowVisible"
      @close="closeModal('isDeleteWindowVisible')"
      @open="openModal"
      :userEarn="user"
    >
    </delete-account>

    <add-slots
      v-if="windows.isSlotsWindowVisible"
      @close="closeModal('isSlotsWindowVisible')"
      @open="openModal"
      :userBalance="user.balance"
    >
    </add-slots>

    <buy-subscription
      v-if="windows.isBuySubWindowVisible"
      @close="closeModal('isBuySubWindowVisible')"
      @open="openModal"
      :userBalance="user.balance"
      :subDays="user.subDays"
    >
    </buy-subscription>

    <balance-top-up
      v-if="windows.isTopUpWindowVisible"
      @close="closeModal('isTopUpWindowVisible')"
      @open="openModal"
    >
    </balance-top-up>

    <methods-replenishment
      v-if="windows.isMethodsReplenishmentWindowVisible"
      @close="closeModal('isMethodsReplenishmentWindowVisible')"
      @open="openModal"
    >
    </methods-replenishment>

    <referral-link-input
      v-if="windows.isReferralLinkInputWindowVisible"
      @close="closeModal('isReferralLinkInputWindowVisible')"
      @open="openModal"
    >
    </referral-link-input>

    <buying-referrals
      v-if="windows.isBuyingReferralsWindowVisible"
      @close="closeModal('isBuyingReferralsWindowVisible')"
      @open="openModal"
      :userBalance="user.balance"
    >
    </buying-referrals>

    <error-window
      v-if="windows.isErrorWindowVisible"
      @close="closeModal('isErrorWindowVisible')"
      @open="openModal"
      :error="windows.isErrorWindowVisible"
    >
    </error-window>

    <success-window
      v-if="windows.isSuccessWindowVisible"
      @close="closeModal('isSuccessWindowVisible')"
      @open="openModal"
      :success="windows.isSuccessWindowVisible"
    >
    </success-window>

    <phone-input
      v-if="windows.isPhoneInputWindowVisible"
      @close="closeModal('isPhoneInputWindowVisible')"
      @open="openModal"
    >
    </phone-input>

    <code-input
      v-if="windows.isCodeInputWindowVisible"
      @close="closeModal('isCodeInputWindowVisible')"
      @open="openModal"
      :phone="windows.isCodeInputWindowVisible"
    >
    </code-input>

    <password-input
      v-if="windows.isPasswordInputWindowVisible"
      @close="closeModal('isPasswordInputWindowVisible')"
      @open="openModal"
      :phone="windows.isPasswordInputWindowVisible"
    >
    </password-input>

    <div
      v-if="showLoad"
      class="loading-container"
    >
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import socket from './socket';
//import { useStore } from 'vuex';
import { mapState, mapActions } from 'vuex';

import AddSlots from './components/modals/AddSlots.vue';
import MethodsReplenishment from './components/modals/MethodsReplenishment.vue';
import BalanceTopUp from './components/modals/BalanceTopUp.vue';
import BalanceTransfer from './components/modals/BalanceTransfer.vue';
import BuySubscription from './components/modals/BuySubscription.vue';
import CodeInput from './components/modals/CodeInput.vue';
import ErrorWindow from './components/modals/ErrorWindow.vue';
import PasswordInput from './components/modals/PasswordInput.vue';
import PhoneInput from './components/modals/PhoneInput.vue';
import SuccessWindow from './components/modals/SuccessWindow.vue';
import WithdrawUSDT from './components/modals/WithdrawUSDT.vue';
import DeleteAccount from './components/modals/DeleteAccount.vue';
import ReferralLinkInput from './components/modals/ReferralLinkInput.vue';
import BuyingReferrals from './components/modals/BuyingReferrals.vue';

export default defineComponent({
  name: 'App',
  components: {
    AddSlots,
    MethodsReplenishment,
    BalanceTopUp,
    BalanceTransfer,
    BuySubscription,
    ReferralLinkInput,
    BuyingReferrals,
    CodeInput,
    DeleteAccount,
    ErrorWindow,
    PasswordInput,
    PhoneInput,
    SuccessWindow,
    WithdrawUSDT,
  },
  computed: {
    ...mapState({
      windows: (state) => state.windows,
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      imagesToPreload: [
        'clear.svg',
        'peng2.png',
        'copy.svg',
        'addAccount.svg',
        'bin.svg',
        'plus.svg',
        'save.svg',
        'tg.svg',
        'plusBalance.svg',
        'eye.svg',
        'eyeClose.svg',
        'settings.svg',
        'wallet.svg',
        'people.svg',
        'failure.svg',
        'sadPenguin.png',
        'peng1.png',
        'arrow.svg',
        'success.svg',
        'buySub.svg',
        'avatar.png',
        'sub.svg',
        'dollar.svg',
        'slot.svg',
        'manage.svg',
        'avatars/beer.png',
        'avatars/blum.png',
        'avatars/hamster.png',
        'avatars/iceberg.png',
        'avatars/memefi.png',
        'avatars/vertus.png',
        'avatars/yescoin.png',
        'avatars/pocketfi.png',
      ],
      showLoad: true,
    };
  },
  mounted() {
    const overflow = 100;
    document.body.style.overflowY = 'hidden';
    document.body.style.marginTop = `${overflow}px`;
    document.body.style.height = window.innerHeight + overflow + 'px';
    document.body.style.paddingBottom = `${overflow}px`;
    window.scrollTo(0, overflow);

    // const store = useStore();
    // this.$router.push('/account');

    // store.commit('SET_SUB_UNTIL', 1733075973);
    // const user = {
    //   name: 'fixee',
    //   balance: 228.0,
    //   slots: 10,
    //   lang: 'ru',
    //   firstLogin: 0,
    //   subDays: 141,
    //   subUntil: 1733075973,
    //   ref: { code: 'f38d351e', count: 2, earn: 10.0, earnAll: 0.0 },
    // };
    // this.updateUser(user);

    window.Telegram.WebApp.expand();

    let loadedCount = 0;
    this.imagesToPreload.forEach((url) => {
      const img = new Image();
      img.onload = () => {
        loadedCount++;
        if (loadedCount === this.imagesToPreload.length) {
          socket.emit('init', window.Telegram.WebApp.initData);
        }
      };
      img.src = require(`@/assets/${url}`);
    });

    socket.on('userData', (userData, firstData) => {
      this.updateUser(userData);
      this.showLoad = false;

      if (firstData) {
        window.Telegram.WebApp.ready();

        this.$i18n.locale = userData.lang === 'ru' ? 'ru' : 'en';
        let route;
        if (userData.firstLogin === 0) {
          if (userData.subUntil > Math.floor(Date.now() / 1000)) {
            route = '/account';
          } else {
            route = '/inactive';
          }
        } else {
          route = '/next';
        }
        this.$router.push(route);
      } else {
        const currentPath = this.$route.path;
        const currentTime = Math.floor(Date.now() / 1000);
        const isSubscriptionExpired = userData.subUntil < currentTime;

        if (currentPath === '/account' && isSubscriptionExpired) {
          this.$router.push('/inactive');
        } else if (currentPath === '/inactive' && !isSubscriptionExpired) {
          this.$router.push('/account');
        }
      }
    });

    socket.on('listAccount', (accounts) => {
      this.updateAccounts(accounts);
    });

    socket.on('disconnect', () => {
      this.$router.push('/error');
    });
  },
  methods: {
    ...mapActions({
      setWindowVisibility: 'setWindowVisibility',
      updateUser: 'updateUser',
      updateAccounts: 'updateAccounts',
    }),
    closeModal(windowName) {
      this.setWindowVisibility({ windowName, value: false });
    },
    openModal(windowName) {
      this.setWindowVisibility({ windowName, value: true });
    },
  },
});
</script>

<style scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: radial-gradient(circle, rgb(125 0 255 / 20%) 0%, transparent 60%);
}

.spinner {
  position: relative;
  width: 80px;
  height: 80px;
  border: 8px solid #d7f2ff;
  border-top: 8px solid #0088ff;
  border-radius: 50%;
  animation: spin 1.4s linear infinite;
}

.spinner::before,
.spinner::after {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  width: 100%;
  height: 100%;
  border: 8px solid transparent;
  border-top: 8px solid rgba(52, 152, 219, 0.5);
  border-radius: 50%;
  filter: blur(8px);
}

.spinner::after {
  filter: blur(16px);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<style>
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button,
input,
label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  display: block;
}

body {
  line-height: 1.5;
  background-color: #0a0a0a;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  overflow: hidden;
  overflow-y: auto;
}
body::-webkit-scrollbar {
  display: none;
}
a {
  text-decoration: none;
  color: inherit;
}

/* Set core body defaults */
/* body {
  margin: 0px;
  padding: 0px;
  height: 100vh;
  
} */

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Make sure textarea without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

@font-face {
  font-family: 'Mont';
  src: url('~@/assets/fonts/Mont-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Mont-Light';
  src: url('~@/assets/fonts/Mont-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Mont-SemiBold';
  src: url('~@/assets/fonts/Mont-SemiBold.woff2') format('woff2');
}
.no-scroll {
  overflow-y: hidden;
}
</style>
